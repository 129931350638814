/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    font-family: Arial, sans-serif;
} */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    background-color: #f8f9fa;
    padding: 20px;

    & h3 {
text-align: center;
font-size: 28px;
color: #000000;
line-height: normal;
@media(max-width: 560px) {
    font-size: 24px;
}
    }

    & img {
        width: 350px;
        height: 350px;
        object-fit: contain;

        @media(max-width:560px) {
            width: 250px;
            height: 250px;
        }
    }
    & p {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        color: #000000;
    }
}

.generate_button,
.submit_button {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background-color: var(--marketplaceColor);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.generate_button:hover,
.submit_button:hover {
    background-color: #0056b3;
}

.centered-image {
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-bottom: 20px;
}

.input_box {
    width: 100%;
    max-width: 370px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    @media(max-width:560px) {
        max-width: 100%;
    }
}

.input_box:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.result {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
}

.layoutWrapperMain {
    min-height: fit-content;
}
.errorMessage {
    color: #ff0000;
    font-size: 16px;
    text-align: center;
}
.buttonWrapper {
    display: flex;
    align-items: center;
    gap: 30px;

    @media(max-width:460px) {
            flex-direction: column;
            width: 100%;
            gap: 10px;
            margin-top: 10px;
        }
    
    & button {
        width: fit-content;
        padding: 7px 18px;

        @media(max-width:460px) {
                width: 100%;
            }
        
    }
}
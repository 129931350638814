@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 1;
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding: 5px 0 3px 0;
  }
}

.checkIcon {
}

.hidden {
  visibility: hidden;
}

.marketplaceFill {
  fill: var(--marketplaceColor);
}

.iconWrapper {

  margin-right: 8px;
  display: flex;

  /* This follows line-height */
  & .iconContent{
      align-self: baseline;
    height: 5px;
    width: 5px;
    background: #000;
    border-radius: 100px;
    @media (max-width: 767px) {
      height: 4px;
      width: 4px;
    }
  }
}

.labelWrapper {
  display: inline-block;
}

.selectedLabel,
.notSelectedLabel {
  composes: marketplaceBodyFontStyles from global;
  line-height: 24px;
  margin: 0;
}

.selectedLabel {
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  font-weight: 500;
  color: #000;
  padding: 2px 0;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.notSelectedLabel {
  display: none;
  color: var(--colorGrey300);
  position: relative;
  text-decoration: line-through;
}
